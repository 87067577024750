<template>
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">Calls</span>
      </h3>
      <div class="card-toolbar">
        <router-link
          :to="{ name: 'call/create' }"
          class="btn btn-info font-weight-bolder font-size-sm mr-3"
        >
          Create Call
        </router-link>
        <date-range-picker
          ref="picker"
          v-model="dateRange"
          :ranges="false"
          style="min-width: 350px;"
          class="mr-3"
        >
        </date-range-picker>
        <button
          type="button"
          class="btn btn-info font-weight-bolder font-size-sm mr-3"
          @click="downloadCsv"
        >
          CSV
        </button>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-0 pb-3">
      <div class="tab-content">
        <!--begin::Table-->
        <div class="table-responsive">
          <table
            class="table table-head-custom table-vertical-center table-head-bg table-borderless"
          >
            <thead>
              <tr class="text-left">
                <th style="min-width: 120px" class="pl-7">
                  <span class="text-dark-75">User</span>
                </th>
                <th style="min-width: 120px">Direction</th>
                <th style="min-width: 100px">Duration</th>
                <th style="min-width: 100px">Status</th>
                <th style="min-width: 100px">Start Time</th>
                <th style="min-width: 100px">End Time</th>
                <th style="min-width: 100px">Play</th>
                <th style="min-width: 100px"></th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(item, i) in list">
                <tr v-bind:key="i">
                  <td class="pl-0 py-8">
                    <span
                      class="text-dark-75 font-weight-bolder d-block font-size-lg"
                      >{{ item.user.firstName }} {{ item.user.lastName }}</span
                    >
                  </td>
                  <td>
                    <span
                      class="text-dark-75 font-weight-bolder d-block font-size-lg"
                      >{{ item.from }}</span
                    >
                  </td>
                  <td>
                    <span
                      class="text-dark-75 font-weight-bolder d-block font-size-lg"
                      >{{ item.totalDuration | time }} ({{
                        item.duration | time
                      }})</span
                    >
                  </td>
                  <td>
                    <span
                      class="text-dark-75 font-weight-bolder d-block font-size-lg"
                      >{{ item.status }}</span
                    >
                  </td>
                  <td>
                    <span
                      class="text-dark-75 font-weight-bolder d-block font-size-lg"
                      >{{ item.startTime | date("DD-MM-YYYY hh:mm") }}</span
                    >
                  </td>
                  <td>
                    <span
                      class="text-dark-75 font-weight-bolder d-block font-size-lg"
                      >{{ item.endTime | date("DD-MM-YYYY hh:mm") }}</span
                    >
                  </td>
                  <td class="pr-0 text-right">
                    <audio controls v-if="item.recording">
                      <source :src="item.recording.fullUrl" type="audio/mpeg" />
                      Your browser does not support the audio tag.
                    </audio>
                  </td>
                  <td class="pr-0 text-right">
                    <router-link
                      :to="{ name: 'call/edit', params: { id: item.id } }"
                      class="btn btn-light-success font-weight-bolder font-size-sm"
                      >Edit</router-link
                    >
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <!--end::Table-->
        <div class="overflow-auto">
          <b-pagination-nav
            :link-gen="linkGen"
            :number-of-pages="total == 0 ? 1 : total / 20 + 1"
            @change="getCalls"
            use-router
          ></b-pagination-nav>
        </div>
      </div>
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  GET_CALLS_LIST,
  DOWNLOAD_CALLS
} from "@/core/services/store/call.module.js";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

export default {
  name: "call-list",
  components: { DateRangePicker },
  data() {
    let startDate = new Date();
    let endDate = new Date();
    startDate.setDate(startDate.getDate() - 6);
    return {
      dateRange: { startDate, endDate }
    };
  },
  mounted() {
    const page = this.$route.query.page || 1;
    this.getCalls(page);
  },
  computed: {
    ...mapState({
      list: state => state.call.calls,
      total: state => state.call.total
    })
  },
  watch: {},
  methods: {
    getCalls(page) {
      this.$store.dispatch(GET_CALLS_LIST, { page, perPage: 20 });
    },
    linkGen(page) {
      return page === 1 ? "?" : `?page=${page}`;
    },
    async downloadCsv() {
      const payload = {
        startDate: this.dateRange.startDate.toISOString().slice(0, 10),
        endDate: this.dateRange.endDate.toISOString().slice(0, 10)
      };
      const data = await this.$store.dispatch(DOWNLOAD_CALLS, payload);

      let blob = new Blob([data], { type: "text/csv" });
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "calls.csv";
      link.click();
    }
  }
};
</script>
